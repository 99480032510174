import React from 'react';
import ReactDOM from 'react-dom/client';
import locale from 'antd/locale/tr_TR';
import dayjs from 'dayjs';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import routes, { print as printRoutes } from './routes';
import App from './App';
import Page404 from './views/Page404';
import Login from './views/Login';
import Print from './views/Print';
import 'antd/dist/reset.css';
import './index.css';
import 'dayjs/locale/tr';

dayjs.locale('tr');

const router = createBrowserRouter([{
  element: (
    <ConfigProvider locale={locale}>
      <App />
    </ConfigProvider>
  ),
  errorElement: <Page404 />,
  children: routes,
}, {
  path: '/login',
  element: <Login />,
}, {
  path: '/print',
  element: (
    <ConfigProvider locale={locale}>
      <Print />
    </ConfigProvider>
  ),
  children: printRoutes,
}]);

ReactDOM.createRoot(document.getElementById('root')).render(<RouterProvider router={router} />);
