import moment from 'moment-timezone';
import 'moment/locale/tr';

export const formatDate = (value, format = 'DD MMMM YYYY, HH:mm') => (value ? moment.unix(value.seconds || value).tz('Europe/Istanbul').locale('tr').format(format) : '');

export const roundFloat = (number, decimalSize = 2) => (Math.round((number + Number.EPSILON)
  * (decimalSize === 3 ? 1000 : 100)) / (decimalSize === 3 ? 1000 : 100));

export const formatK = (value, decimalSize) => (value.toLocaleString('usa', {
  minimumFractionDigits: Number.isInteger(decimalSize) ? decimalSize : 2,
}));

export const formatEuro = (value = 0) => (`${formatK(roundFloat(value))} €`);

export const filterOption = (input, option) => (option.label || '').toLowerCase('tr').includes(input.toLowerCase('tr'));

export const createOfferValues = ['client_id', 'name', 'en', 'boy', 'total_piece', 'material', 'set_assortment', 'bicak', 'lak', 'selofan', 'emboss', 'yaldiz', 'notes'];
