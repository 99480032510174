import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import {
  Card, Form, Button, Input, InputNumber, Row, Col, Select, Space, Steps,
  DatePicker, Checkbox, Descriptions, Radio, Image, notification, Tooltip, Modal,
} from 'antd';
import { SaveOutlined, CloseOutlined, PrinterFilled } from '@ant-design/icons';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import omit from 'lodash.omit';
import routes from '../../utils/routes';
import collections from '../../utils/collections';
import {
  addDoc, updateDoc, getDoc, getDocs, queryWhere, where, auth,
} from '../../utils/firebase';
import { GlobalContext } from '../../contexts/global';
import { ORDER_IS, ORDER_STATUS, ORDER_PRINT_TYPES } from '../../utils/options';
import Page404 from '../Page404';

const { TextArea } = Input;
const REQUIRED_MESSAGE = 'Bu alan zorunludur.';
const required = [{
  required: true,
  message: REQUIRED_MESSAGE,
}];
const numberConf = {
  controls: false,
  min: 0,
  decimalSeparator: ',',
  precision: 0,
  style: {
    width: '100%',
  },
};
const now = dayjs();
const dateConf = {
  minDate: now,
};

export default function OrdersCreate() {
  const { orderId } = useParams();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const navigate = useNavigate();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [searchParams] = useSearchParams();
  const {
    getPaper, getUserFullName, stockPaperFilter, syncStockPaper,
    USERS, isAdmin, isTemsilci, isUretim, isGrafik,
  } = useContext(GlobalContext);
  const [offer, setOffer] = useState(null);
  const [statusCurrent, setStatusCurrent] = useState(0);
  const [grapher, setGrapher] = useState(null);
  const [orderDetail, setOrderDetail] = useState(null);
  const printType = Form.useWatch('print_type', form);
  const stockMaterialCode = Form.useWatch('stock_material_code', form);
  const stockEn = Form.useWatch('stock_en', form);
  const bicakVorey = Form.useWatch('vorey', form);
  const bicakYaprak = Form.useWatch('yaprak_bicak', form);
  const bicakTahta = Form.useWatch('tahta_bicak', form);
  const isPrintTypeFlekso = useMemo(() => printType === 'Flekso', [printType]);
  const [padActive, setPadActive] = useState(false);
  const [psdActive, setPsdActive] = useState(false);
  const [stockDetail, setStockDetail] = useState({});
  const [showStockUpdate, setShowStockUpdate] = useState(false);
  const [showOfferLakUpdate, setShowOfferLakUpdate] = useState(false);
  const [showOfferSelofanUpdate, setShowOfferSelofanUpdate] = useState(false);
  const canChangeStock = useMemo(
    () => !orderId || !orderDetail?.stock_consumed_size
      ?.flatMap((s) => Object.values(s))
      .some((n) => n > 0),
    [orderId, orderDetail],
  );
  const bicakRequired = useMemo(
    () => (!(bicakVorey || bicakYaprak || bicakTahta)),
    [bicakVorey, bicakYaprak, bicakTahta],
  );

  useEffect(() => {
    setPadActive(statusCurrent > 0);
    setPsdActive(statusCurrent > 1);
  }, [statusCurrent]);

  useEffect(() => {
    form.validateFields(['print_approval_date', 'production_start_date']);
  }, [padActive, psdActive, form]);

  useEffect(() => {
    setPadActive(statusCurrent > 0);
    setPsdActive(statusCurrent > 1);
  }, [statusCurrent]);

  useEffect(() => {
    const offerId = searchParams.get('id');

    if (offerId && auth) {
      checkIfOrderExist(offerId);
      loadPreData(offerId);
    }
  }, [searchParams, isAdmin, auth]);

  async function loadPreData(id) {
    setLoading(true);

    await getDoc(collections.OFFERS, id)
      .then((data) => {
        if (!isAdmin && data.created_by !== auth.currentUser.uid) {
          return false;
        }

        setOffer(data);

        const others = [];

        if (data.yaldiz_type && data.yaldiz_type.length) others.push(`${data.yaldiz_type} Yaldız`);
        if (data.emboss === 'Var') others.push('Emboss');

        form.setFieldsValue({
          lak: data.lak,
          selofan: data.selofan,
          others,
        });
      }).finally(() => {
        setLoading(false);
      });
  }

  async function checkIfOrderExist(offerId) {
    const query = queryWhere(collections.ORDERS, where('offer.id', '==', offerId));

    await getDocs(query).then((data) => {
      if (data[0]?.id) {
        navigate(routes.ORDER_DETAIL.replace(':orderId', data[0].id));
      }
    });
  }

  async function getOrderDetail() {
    setLoading(true);

    await getDoc(collections.ORDERS, orderId)
      .then((data) => {
        setOrderDetail(data);
        setOffer(data.offer);
      }).finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (orderId && !orderDetail) {
      getOrderDetail();
    }
  }, [orderId, orderDetail]);

  useEffect(() => {
    if (orderDetail) {
      const dateKeys = ['order_date', 'print_approval_date', 'production_start_date', 'shipment_date'];
      const nonFormKeys = ['created_at', 'created_by', 'id', 'grapher', 'status', 'offer', ...dateKeys];

      form.setFieldsValue(omit(orderDetail, nonFormKeys));
      setStatusCurrent(orderDetail.status);
      setGrapher(orderDetail.grapher);

      dateKeys.forEach((key) => {
        if (orderDetail[key]) {
          form.setFieldValue(key, dayjs.unix(orderDetail[key]));
        }
      });
    }
  }, [orderDetail]);

  useEffect(() => {
    if (offer) {
      Object.values(stockPaperFilter).forEach((p) => {
        const mt = p[offer.material];
        if (mt) setStockDetail(mt);
      });
    }
  }, [offer, stockPaperFilter]);

  const stockMaterialCodes = useMemo(() => Object.keys(stockDetail).map((label) => ({
    label,
    value: label,
  })), [stockDetail]);

  const stockMaterialEn = useMemo(() => Object
    .keys(stockDetail[stockMaterialCode] || {}).map((label) => ({
      label,
      value: label,
    })), [stockMaterialCode]);

  const sections = [[{
    label: 'Firma Ticaret Ünvanı',
    children: offer?.client_name,
  }, {
    label: 'İşin Adı',
    children: offer?.name,
  }, {
    label: 'Satış Temsilcisi',
    children: getUserFullName(offer?.client_representative),
  }, {
    label: 'Sipariş Tarihi',
    children: (
      <Form.Item name="order_date" rules={required}>
        <DatePicker placeholder="Tarih Seçiniz" {...dateConf} />
      </Form.Item>
    ),
  }, {
    label: 'Baskı Onay Tarihi',
    children: (
      <Tooltip title="Baskı Onay Tarihi'ni sadece grafik ekibi belirleyebilir.">
        <Form.Item name="print_approval_date" rules={[{ ...required[0], required: padActive }]}>
          <DatePicker
            placeholder="Tarih Seçiniz"
            {...dateConf}
            disabledDate={() => !isGrafik}
            allowClear={isGrafik}
          />
        </Form.Item>
      </Tooltip>
    ),
  }, {
    label: 'Üretim Baslangıç Tarihi',
    children: (
      <Form.Item name="production_start_date" rules={[{ ...required[0], required: psdActive }]}>
        <DatePicker placeholder="Tarih Seçiniz" {...dateConf} />
      </Form.Item>
    ),
  }], [{
    label: ORDER_PRINT_TYPES[0].label,
    children: (<Radio value={ORDER_PRINT_TYPES[0].value} />),
  }, {
    label: ORDER_PRINT_TYPES[1].label,
    children: (<Radio value={ORDER_PRINT_TYPES[1].value} />),
  }, {
    label: ORDER_PRINT_TYPES[2].label,
    children: (<Radio value={ORDER_PRINT_TYPES[2].value} />),
  }, {
    label: ORDER_PRINT_TYPES[3].label,
    children: (<Radio value={ORDER_PRINT_TYPES[3].value} />),
  }, {
    label: ORDER_PRINT_TYPES[4].label,
    children: (<Radio value={ORDER_PRINT_TYPES[4].value} />),
  }, {
    label: 'İş',
    children: (
      <Form.Item name="order_type" rules={required}>
        <Select placeholder="Seçiniz" options={ORDER_IS} />
      </Form.Item>
    ),
  }], [], [{
    label: 'Kağıt Türü',
    children: getPaper(offer?.material)?.name,
  }, {
    label: 'En (mm)',
    children: offer?.en,
  }, {
    label: 'Boy (mm)',
    children: offer?.boy,
  }, {
    label: 'Malzeme Kodu',
    children: (
      <Form.Item name="stock_material_code">
        <Select
          disabled={!canChangeStock}
          placeholder="Seçiniz"
          options={stockMaterialCodes}
          onChange={() => {
            setShowStockUpdate(true);
            form.resetFields(['stock_en', 'stock_consumed_size']);
          }}
        />
      </Form.Item>
    ),
  }, {
    label: 'Bobin En (mm)',
    children: (
      <Form.Item name="stock_en">
        <Select
          disabled={!canChangeStock}
          placeholder="Seçiniz"
          options={stockMaterialEn}
          onChange={() => {
            setShowStockUpdate(true);
            form.resetFields(['stock_consumed_size']);
          }}
        />
      </Form.Item>
    ),
  }, {
    label: 'Tüketilen Boy',
    children: (
      stockEn ? (
        <div style={{ maxWidth: 300 }}>
          <Space direction="vertical">
            {stockDetail[stockMaterialCode][stockEn].map((bobin, index) => {
              const max = Math.max((bobin.boy - bobin.consumed_boy), 0);
              return (
                <Form.Item
                  name={['stock_consumed_size', index, stockDetail[stockMaterialCode][stockEn][index].id]}
                  rules={required}
                  key={index}
                >
                  <InputNumber
                    disabled={!canChangeStock}
                    {...numberConf}
                    max={max}
                    addonBefore={`Bobin ${index + 1} - Kalan Boy: ${max}`}
                    onChange={() => setShowStockUpdate(true)}
                  />
                </Form.Item>
              );
            })}
          </Space>
        </div>
      ) : (
        <Input disabled />
      )
    ),
  }], [{
    label: 'Renk',
    children: (
      <Form.Item name="color" rules={required}>
        <Checkbox.Group
          options={[{
            label: 'C', value: 'C',
          }, {
            label: 'M', value: 'M',
          }, {
            label: 'Y', value: 'Y',
          }, {
            label: 'K', value: 'K',
          }, {
            label: 'W', value: 'W',
          }]}
        />
      </Form.Item>
    ),
  }, {
    label: 'P1',
    children: (
      <Form.Item name="p1">
        <Input />
      </Form.Item>
    ),
  }, {
    label: 'P2',
    children: (
      <Form.Item name="p2">
        <Input />
      </Form.Item>
    ),
  }, {
    label: 'Lak',
    span: 3,
    children: (
      <Form.Item
        name="lak"
        rules={required}
        onChange={(event) => {
          setShowOfferLakUpdate(event.target.value !== offer.lak);
        }}
      >
        <Radio.Group name="lak_radio">
          <Radio value="Yok">Yok</Radio>
          <Radio value="Mat">Mat</Radio>
          <Radio value="Parlak">Parlak</Radio>
          <Radio value="Ribon">Ribon</Radio>
        </Radio.Group>
      </Form.Item>
    ),
  }, {
    label: 'Selofan',
    span: 3,
    children: (
      <Form.Item
        name="selofan"
        rules={required}
        onChange={(event) => {
          setShowOfferSelofanUpdate(event.target.value !== offer.selofan);
        }}
      >
        <Radio.Group name="selofan_radio">
          <Radio value="Yok">Yok</Radio>
          <Radio value="Mat">Mat</Radio>
          <Radio value="Soft Touch">Soft Touch</Radio>
        </Radio.Group>
      </Form.Item>
    ),
  }, {
    label: 'Diğer',
    span: 3,
    children: (
      <Form.Item name="others">
        <Checkbox.Group
          options={[{
            label: 'Soğuk Yaldız', value: 'Soğuk Yaldız',
          }, {
            label: 'Sıcak Yaldız', value: 'Sıcak Yaldız',
          }, {
            label: 'Emboss', value: 'Emboss',
          }]}
        />
      </Form.Item>
    ),
  }], [{
    label: 'Yapıştırma',
    span: 2,
    children: (
      <Form.Item name="bonding_type" rules={required}>
        <Radio.Group name="bonding_type_radio">
          <Radio value="Makine">Makine</Radio>
          <Radio value="El">El</Radio>
        </Radio.Group>
      </Form.Item>
    ),
  }, {
    label: 'Kuka',
    span: 2,
    children: (
      <Form.Item name="kuka" rules={required}>
        <Radio.Group name="kuka_radio">
          <Radio value="25">25</Radio>
          <Radio value="40">40</Radio>
          <Radio value="50">50</Radio>
          <Radio value="76">76</Radio>
        </Radio.Group>
      </Form.Item>
    ),
  }, {
    label: 'Rulo Yönü',
    children: (
      <Form.Item name="roll_direction" rules={required}>
        <Radio.Group name="roll_direction_radio">
          <Radio value={1}><Image width={80} preview={false} src="/images/YON01.png" /></Radio>
          <Radio value={2}><Image width={80} preview={false} src="/images/YON02.png" /></Radio>
          <Radio value={3}><Image width={80} preview={false} src="/images/YON03.png" /></Radio>
          <Radio value={4}><Image width={80} preview={false} src="/images/YON04.png" /></Radio>
          <Radio value={5}><Image width={80} preview={false} src="/images/YON05.png" /></Radio>
          <Radio value={6}><Image width={80} preview={false} src="/images/YON06.png" /></Radio>
          <Radio value={7}><Image width={80} preview={false} src="/images/YON07.png" /></Radio>
          <Radio value={8}><Image width={80} preview={false} src="/images/YON08.png" /></Radio>
        </Radio.Group>
      </Form.Item>
    ),
  }], [[{
    label: 'Vorey Kesim',
    span: 3,
    children: (
      <Form.Item name="vorey" rules={[{ ...required[0], required: bicakRequired }]}>
        <Checkbox.Group
          options={[{
            label: '', value: 1,
          }]}
        />
      </Form.Item>
    ),
  }], [{
    label: 'Tahta Bıçak',
    span: 3,
    children: (
      <Form.Item name="tahta_bicak" rules={[{ ...required[0], required: bicakRequired }]}>
        <Radio.Group name="tahta_bicak_radio">
          <Radio value="Var">Var</Radio>
          <Radio value="Yok">Yok</Radio>
        </Radio.Group>
      </Form.Item>
    ),
  }, {
    label: 'Yan Yana',
    span: 3,
    children: (
      <Form.Item name="tahta_bicak_yanyana">
        <InputNumber />
      </Form.Item>
    ),
  }, {
    label: 'Ara Boşluk',
    span: 3,
    children: (
      <Form.Item name="tahta_bicak_arabosluk">
        <InputNumber />
      </Form.Item>
    ),
  }], [{
    label: 'Yaprak Bıçak',
    span: 3,
    children: (
      <Form.Item name="yaprak_bicak" rules={[{ ...required[0], required: bicakRequired }]}>
        <Radio.Group name="yaprak_bicak_radio">
          <Radio value="Var">Var</Radio>
          <Radio value="Yok">Yok</Radio>
        </Radio.Group>
      </Form.Item>
    ),
  }, {
    label: 'Yan Yana',
    span: 3,
    children: (
      <Form.Item name="yaprak_bicak_yanyana">
        <InputNumber />
      </Form.Item>
    ),
  }, {
    label: 'Ara Boşluk',
    span: 3,
    children: (
      <Form.Item name="yaprak_bicak_arabosluk">
        <InputNumber />
      </Form.Item>
    ),
  }, {
    label: 'Zet',
    span: 3,
    children: (
      <Form.Item name="yaprak_bicak_zet">
        <InputNumber />
      </Form.Item>
    ),
  }], [{
    label: 'Flekso/Klişe',
    span: 3,
    children: (
      <Form.Item name="flekso_klise">
        <Radio.Group name="flekso_klise_radio" disabled={!isPrintTypeFlekso}>
          <Radio value="Var">Var</Radio>
          <Radio value="Yok">Yok</Radio>
        </Radio.Group>
      </Form.Item>
    ),
  }, {
    label: 'Yan Yana',
    span: 3,
    children: (
      <Form.Item name="flekso_klise_yanyana">
        <InputNumber disabled={!isPrintTypeFlekso} />
      </Form.Item>
    ),
  }, {
    label: 'Ara Boşluk',
    span: 3,
    children: (
      <Form.Item name="flekso_klise_arabosluk">
        <InputNumber disabled={!isPrintTypeFlekso} />
      </Form.Item>
    ),
  }, {
    label: 'Zet',
    span: 3,
    children: (
      <Form.Item name="flekso_klise_zet">
        <InputNumber disabled={!isPrintTypeFlekso} />
      </Form.Item>
    ),
  }]], [{
    label: 'Sarım Çapı',
    span: 2,
    children: (
      <Form.Item name="sarim_capi">
        <InputNumber />
      </Form.Item>
    ),
  }, {
    label: 'Sarım Adedi',
    span: 2,
    children: (
      <Form.Item name="sarim_adedi">
        <InputNumber />
      </Form.Item>
    ),
  }, {
    label: 'Toplam Etiket Adedi',
    span: 2,
    children: (
      <Form.Item name="toplam_etiket_adedi">
        <InputNumber />
      </Form.Item>
    ),
  }, {
    label: 'Sevkiyat Tarihi',
    span: 2,
    children: (
      <Form.Item name="shipment_date">
        <DatePicker placeholder="Tarih Seçiniz" {...dateConf} />
      </Form.Item>
    ),
  }], [{
    label: 'İş Emri Notları',
    children: (
      <Form.Item name="notes">
        <TextArea />
      </Form.Item>
    ),
  }]];

  function onSuccess(description = '') {
    notificationApi.success({
      message: 'İşlem Başarılı',
      description,
    });
  }

  function onError(description = '') {
    notificationApi.error({
      message: 'Hata',
      description,
    });

    setSaveLoading(false);
  }

  function skipOfferBase() {
    setShowOfferLakUpdate(false);
    setShowOfferSelofanUpdate(false);
    setTimeout(() => form.submit(), 500);
  }

  async function onSubmit(values) {
    setSaveLoading(true);

    const order = {
      ...values,
      status: statusCurrent,
      grapher,
      offer,
    };

    Object.entries(order).filter((v) => (v[1] === '')).forEach((item) => {
      order[item[0]] = null;
    });

    Object.keys(order).filter((v) => v.includes('_date')).forEach((item) => {
      if (order[item]) {
        order[item] = order[item].unix();
      }
    });

    if (!isPrintTypeFlekso) {
      order.flekso_klise = null;
      order.flekso_klise_yanyana = null;
      order.flekso_klise_arabosluk = null;
      order.flekso_klise_zet = null;
    }

    if (!isAdmin) {
      if (!isGrafik) {
        order.print_approval_date = undefined;
      }

      if (!isUretim) {
        const fields = ['stock_consumed_size', 'production_start_date', 'sarim_capi', 'sarim_adedi', 'toplam_etiket_adedi', 'shipment_date'];

        Object.keys(order).forEach((key) => {
          if (fields.includes(key)) order[key] = undefined;
        });
      }
    }

    ['stock_material_code', 'stock_en', 'stock_consumed_size'].forEach((k) => {
      // Clear if the input resetted.
      if (!order[k]) {
        order[k] = null;
      }
    });

    if (showOfferLakUpdate || showOfferSelofanUpdate) {
      const title = showOfferLakUpdate && showOfferSelofanUpdate
        ? 'Lak ve selofan değerlerini'
        : showOfferLakUpdate
          ? 'Lak değerini'
          : 'Selofan değerini';

      const message = `İşleme teklifden farklı ${showOfferLakUpdate && showOfferSelofanUpdate ? 'değerler' : 'değer'} ile devam ederseniz mükerrer kayıt oluşacaktır.`;

      Modal.confirm({
        title: `${title} değiştirdiniz.`,
        content: (
          <div>
            <p>{message}</p>
          </div>
        ),
        closable: false,
        okText: 'Devam Et',
        onOk() {
          skipOfferBase();
        },
        cancelText: 'Vazgeç',
        onCancel() {
          setSaveLoading(false);
        },
      });

      return;
    }

    if (showStockUpdate && order.stock_consumed_size) {
      Modal.confirm({
        title: 'Stok Çıkış',
        content: (
          <div>
            <p>Stokları kayıtlarını etkileyecek bir değişiklik yaptınız.</p>
            <p>
              İşleme devam ederseniz stok çıkış işlemi yapılacaktır,&nbsp;
              <u>bu işlem geri alınamaz.</u>
            </p>
          </div>
        ),
        closable: false,
        okText: 'Devam Et',
        onOk() {
          makeStockOut(order);
        },
        cancelText: 'Vazgeç',
        onCancel() {
          setSaveLoading(false);
        },
      });

      return;
    }

    if (orderId) {
      await updateDoc(collections.ORDERS, orderId, order).then(() => {
        onSuccess('İş emri güncellendi.');
      });
    } else {
      await addDoc(collections.ORDERS, order).then(() => {
        onSuccess('İş emri oluşturuldu.');

        setTimeout(() => {
          navigate(routes.ORDERS);
        }, 500);
      });
    }

    setSaveLoading(false);
  }

  async function makeStockOut(order) {
    try {
      const requests = [];
      const bobins = Object.values(stockPaperFilter).find((sp) => Object
        .keys(sp).includes(order.offer.material))[order.offer.material][
        order.stock_material_code][order.stock_en];

      const afterFilter = order.stock_consumed_size
        .filter((s) => Object.values(s)[0])
        .map((s) => {
          const [id] = Object.keys(s);
          const [value] = Object.values(s);
          return {
            bobin: bobins.find((b) => b.id === id),
            usage: value,
          };
        });

      afterFilter.forEach(async ({ bobin, usage }) => {
        const next = (bobin.consumed_boy + usage);

        if (!(bobin.boy - next >= 0)) {
          onError('Girilen miktarda stok kalmamıştır.');
          return;
        }

        requests.push(
          updateDoc(collections.STOCK_PAPER, bobin.id, {
            ...bobin,
            id: undefined,
            consumed_boy: next,
          }),
          addDoc(collections.STOCK_PAPER_OUT, {
            ...bobin,
            id: undefined,
            original_id: bobin.id,
            out: usage,
            next_consumed_boy: next,
            via: 'ORDER',
          }),
        );
      });

      await Promise.all(requests).then(() => {
        setShowStockUpdate(false);
        syncStockPaper();
        onSuccess('Kağıt stok çıkış işlemi gerçekleşti.');
        setTimeout(() => form.submit(), 100);
      });
    } catch (err) {
      onError('Şu an işleminizi gerçekleştiremiyoruz.');
    }
  }

  function handlePrint() {
    window.open(`/print/orders/${orderId}`, '', 'width=800,height=800');
  }

  if (!(isAdmin || isTemsilci || isUretim || isGrafik)) {
    return <Page404 />;
  }

  return (
    <Row>
      <Col lg={24} xl={20}>
        <Card
          title={orderId ? 'İş Emri Düzenle' : 'Yeni İş Emri Oluştur'}
          bordered={false}
          loading={isLoading}
          extra={(
            <Space>
              <span>Grafiker:</span>
              <Select
                className="w-200p"
                placeholder="Seçiniz"
                options={USERS.GRAFIK}
                onChange={(value) => { setGrapher(value); }}
                value={grapher}
              />
            </Space>
          )}
        >
          {offer ? (
            <Form
              form={form}
              initialValues={{
                status: statusCurrent,
              }}
              onFinish={onSubmit}
              onFinishFailed={() => window.scrollTo(0, 0)}
              autoComplete="off"
              layout="horizontal"
            >
              <Form.Item name="status">
                <Steps
                  current={statusCurrent}
                  items={ORDER_STATUS}
                  onChange={(value) => setStatusCurrent(value)}
                />
              </Form.Item>
              <Descriptions className="mt-2r" size="small" bordered items={sections[0]} />
              <Form.Item name="print_type" rules={required}>
                <Radio.Group className="w-100" name="print_type_radio">
                  <Descriptions className="mt-2r" size="small" bordered items={sections[1]} />
                </Radio.Group>
              </Form.Item>
              <Descriptions className="mt-2r" size="small" bordered items={sections[3]} />
              <Descriptions className="mt-2r" size="small" bordered items={sections[4]} />
              <Descriptions className="mt-2r" size="small" bordered title="Etiket Akış Bilgileri" items={sections[5]} />
              <Row className="mt-2r" gutter={[32, 0]}>
                <Col span={24} className="ant-descriptions">
                  <div className="ant-descriptions-header">
                    <div className="ant-descriptions-title">Bıçak ve Klişe Bilgileri</div>
                  </div>
                </Col>
                <Col span={6}>
                  {/* Vorey Kesim */}
                  <Descriptions size="small" bordered items={sections[6][0]} />
                </Col>
                <Col span={6}>
                  {/* Tahta Bıçak */}
                  <Descriptions size="small" bordered layout="vertical" items={sections[6][1]} />
                </Col>
                <Col span={6}>
                  {/* Yaprak Bıçak */}
                  <Descriptions size="small" bordered layout="vertical" items={sections[6][2]} />
                </Col>
                <Col span={6}>
                  {/* Flekso/Klişe */}
                  <Descriptions size="small" bordered layout="vertical" items={sections[6][3]} />
                  {!isPrintTypeFlekso && (
                    <div className="disabled-overlay flekso" />
                  )}
                </Col>
              </Row>
              <Descriptions className="mt-2r" size="small" bordered title="Üretim Bilgileri" items={sections[7]} />
              <Descriptions className="mt-2r" size="small" bordered title="Notlar" items={sections[8]} />
              <Col
                style={{
                  marginTop: '2rem',
                  textAlign: 'right',
                }}
              >
                <Space>
                  <Button
                    size="large"
                    icon={React.createElement(PrinterFilled)}
                    onClick={handlePrint}
                  >
                    Yazdır
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    danger
                    icon={React.createElement(CloseOutlined)}
                    onClick={() => { navigate(routes.ORDERS); }}
                  >
                    İptal
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    icon={React.createElement(SaveOutlined)}
                    loading={saveLoading}
                  >
                    Kaydet
                  </Button>
                </Space>
              </Col>
            </Form>
          ) : (
            <p>Teklif bulunamadı. Lütfen teklif ekranından geliniz.</p>
          )}
        </Card>
      </Col>
      {contextHolder}
    </Row>
  );
}
