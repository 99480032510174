import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Image } from 'antd';
import { GlobalContext } from '../../contexts/global';
import collections from '../../utils/collections';
import { formatDate } from '../../utils/common';
import { getDoc } from '../../utils/firebase';
import { ORDER_STATUS } from '../../utils/options';

const DATE_FORMAT = 'DD/MM/YYYY';

export default function Orders() {
  const { orderId } = useParams();
  const [orderDetail, setOrderDetail] = useState(null);
  const [offer, setOffer] = useState(null);

  async function getOrderDetail() {
    await getDoc(collections.ORDERS, orderId)
      .then((data) => {
        setOrderDetail(data);
        setOffer(data.offer);
        setTimeout(() => {
          handlePrint();
        }, 100);
        setTimeout(() => {
          window.onmousemove = function () {
            window.close();
          };
        }, 1000);
      });
  }

  const { getPaper, getUserFullName } = useContext(GlobalContext);
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    document.title = `Asal_Order_${orderId}`;
    getOrderDetail();
  }, [orderId]);

  return (
    <div ref={printRef} style={{ padding: 20 }}>
      {orderDetail && offer && (
        <table className="print-table">
          <tr className="no-border">
            <table className="print-table">
              <tr>
                <td colSpan="3" className="no-padding no-border">
                  <table className="print-table">
                    <tr>
                      <td style={{ width: '50%' }}>
                        <span className="title">Firma Ticaret Ünvanı</span>
                        {offer.client_name}
                      </td>
                      <td>
                        <span className="title">Durum</span>
                        {ORDER_STATUS.find((s) => s.value === orderDetail.status)?.title}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '50%' }}>
                        <span className="title">İşin Adı</span>
                        {offer.name}
                      </td>
                      <td>
                        <span className="title">Satış Temsilcisi</span>
                        {getUserFullName(offer.client_representative)}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr><td colSpan="3" className="no-border" /></tr>
              <tr>
                <td>
                  <span className="title">Sipariş Tarihi</span>
                  {formatDate(orderDetail.order_date, DATE_FORMAT) || '--'}
                </td>
                <td>
                  <span className="title">Baskı Onay Tarihi</span>
                  {formatDate(orderDetail.print_approval_date, DATE_FORMAT) || '--'}
                </td>
                <td>
                  <span className="title">Üretim Baslangıç Tarihi</span>
                  {formatDate(orderDetail.production_start_date, DATE_FORMAT) || '--'}
                </td>
              </tr>
              <tr>
                <td>
                  <span className="title">Baskı Türü</span>
                  {orderDetail.print_type_radio || '--'}
                </td>
                <td>
                  <span className="title">İş Türü</span>
                  {orderDetail.order_type || '--'}
                </td>
                <td>
                  <span className="title">Grafiker</span>
                  {orderDetail.grapher || '--'}
                </td>
              </tr>
              <tr><td colSpan="3" className="no-border" /></tr>
              <tr>
                <td>
                  <span className="title">Kağıt Türü</span>
                  {getPaper(offer?.material)?.name || '--'}
                </td>
                <td>
                  <span className="title">En (mm)</span>
                  {offer?.en || '--'}
                </td>
                <td>
                  <span className="title">Boy (mm)</span>
                  {offer?.boy || '--'}
                </td>
              </tr>
              <tr>
                <td>
                  <span className="title">Renk</span>
                  {orderDetail.color?.join(', ') || '--'}
                </td>
                <td>
                  <span className="title">P1</span>
                  {orderDetail.p1 || '--'}
                </td>
                <td>
                  <span className="title">P2</span>
                  {orderDetail.p2 || '--'}
                </td>
              </tr>
              <tr>
                <td>
                  <span className="title">Lak</span>
                  {orderDetail.lak || '--'}
                </td>
                <td>
                  <span className="title">Selofan</span>
                  {orderDetail.selofan || '--'}
                </td>
                <td>
                  <span className="title">Diğer</span>
                  {orderDetail.others?.join(', ') || '--'}
                </td>
              </tr>
              <tr><td colSpan="3" className="no-border" /></tr>
              <tr>
                <td colSpan="3" className="no-padding no-border">
                  <table className="print-table">
                    <tr>
                      <td style={{ width: '50%' }}>
                        <span className="title">Yapıştırma</span>
                        {orderDetail.bonding_type}
                      </td>
                      <td rowSpan="2" align="center" valign="center">
                        <span className="title">Rulo Yönü</span>
                        {orderDetail.roll_direction ? (
                          <Image width={80} preview={false} src={`/images/YON0${orderDetail.roll_direction}.png`} />
                        ) : '--'}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '50%' }}>
                        <span className="title">Kuka</span>
                        {orderDetail.kuka}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr><td colSpan="3" className="no-border" /></tr>
              <tr>
                <td colSpan="3" className="no-border no-padding">
                  <table className="print-table">
                    <tr>
                      <td>
                        <span className="title">Sarım Çapı</span>
                        {orderDetail.sarim_capi || '--'}
                      </td>
                      <td>
                        <span className="title">Sarım Adedi</span>
                        {orderDetail.sarim_adedi || '--'}
                      </td>
                      <td>
                        <span className="title">Toplam Etiket Adedi</span>
                        {orderDetail.toplam_etiket_adedi || '--'}
                      </td>
                      <td>
                        <span className="title">Sevkiyat Tarihi</span>
                        {formatDate(orderDetail.shipment_date, DATE_FORMAT) || '--'}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr><td colSpan="3" className="no-border" /></tr>
              <tr>
                <td colSpan="3">
                  <span className="title">İş Emri Notları</span>
                  {orderDetail.notes || '--'}
                </td>
              </tr>
            </table>
          </tr>
        </table>
      )}
    </div>
  );
}
